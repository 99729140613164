import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';
import ToggleButtonGroupMui, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
  },

  groupedHorizontal: {
    paddingBlock: '20px',
    borderColor: theme.palette.darkGrey[15],
    '&:not(:first-child)': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderColor: `${theme.palette.darkGrey[15]} !important`,
      marginLeft: (props: { spacing: number }) => theme.spacing(props.spacing),
    },
    'button&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      borderColor: `${theme.palette.darkGrey[15]} !important`,
    },

    '&:not(:last-child)': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
}));

interface Props extends ToggleButtonGroupProps {
  spacing?: number;
  name?: string;
}

function ToggleButtonGroup({ spacing = 3, ...others }: Props) {
  const classes = useStyles({ spacing });

  return (
    <ToggleButtonGroupMui classes={{ root: classes.root, groupedHorizontal: classes.groupedHorizontal }} {...others} />
  );
}

export default ToggleButtonGroup;
