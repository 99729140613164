import { inventoriesApi } from 'utils/api/baas/inventories/axiosBase';
import { connectApi } from 'utils/api/baas/axiosBase';

import { getUserCurrentStore } from 'utils/auth';

export async function getProductAssociations(sku: string, isSoldAlone: boolean) {
  const store = getUserCurrentStore()?.uuid;
  const response = await inventoriesApi.get(`/v1/products/${sku}?store_uuid=${store}&is_sold_alone=${isSoldAlone}`);
  return response.data;
}

export interface DeleteProductRequestProps {
  uuid: string;
  recipeAction: string;
}

export async function deleteProduct(params: DeleteProductRequestProps) {
  const store = getUserCurrentStore()?.uuid;

  const response = await connectApi.delete(`/v1/menu-maker/products/${params.uuid}/`, {
    params: {
      store_uuid: store,
      recipe_action: params.recipeAction,
    },
  });
  return response.data;
}
